import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#34363F'}}>
      
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridColumnGap: '20px'}}>
        <div style={{ backgroundColor: '#34363F', padding: '20px', color: "#fff", alignContent:"center" ,paddingLeft: "30%" }}>
        <p style={{color:"#FFFFFF",fontSize:"24px",lineHeight:"33px"}}>联系我们</p>
          <div style={{fontSize:"16px",color:"#DEDEDE"}}>憬昆金融官网：http://www.dbsisjj.com/</div>
          <p  style={{fontSize:"16px",color:"#DEDEDE"}}>公司总机：4006386036</p>
          <div style={{ display: 'flex',fontSize:"16px",color:"#DEDEDE" }}>
            <div>办公地址：</div>
            <div>四川省成都市武侯区楚峰国际中心b座3808</div>
          </div>
        </div>

        <div style={{ backgroundColor: '#34363F',alignContent:"center" ,padding: '20px', color: "#fff", paddingLeft: "15%" }}>
          <p style={{fontSize:"16px",color:"#DEDEDE"}}>人才招聘：wangshu2802@dingtalk.com</p>
          <p style={{fontSize:"16px",color:"#DEDEDE"}}>商务合作：zhouxiaodi7545@dingtalk.com</p>
        </div>

        <div style={{textAlign:"center", backgroundColor: '#34363F', padding: '80px 30% 50px 20px', color: "#fff",}}>
          <span style={{color:"#D8D8D8",fontSize:"24px",lineHeight:"33px"}}>扫下方二维码关注我们</span>
          <div style={{ display: "flex",justifyContent: "space-around",marginTop:"15px" }}>
            <div style={{display:"flex",flexDirection:"column",alignContent:"center"}}><img style={{marginBottom:"11px"}} src="img/img4.png" alt="" />打开微信扫一扫</div>
            <div style={{display:"flex",flexDirection:"column",alignContent:"center"}}><img style={{marginBottom:"11px"}} src="img/img6.png" alt="" />扫一扫下载APP</div>
          </div>
        </div>
      </div>
      <div className='footer_bottom' style={{ width: "100%", color: "#fff", height: "100px", lineHeight: "100px", backgroundColor: "#1F1F1F", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
        <div style={{ color: "#fff",
            fontSize:"16px"}}>成都憬昆金融服务外包有限公司版权所有</div>
        <div>
          <a style={{
            textDecoration: "none",
            color: "#fff",
            fontSize:"16px"
          }} title="域名信息备案管理-工信部" href="https://beian.miit.gov.cn/#/Integrated/index">
            <img style={{
              display: "inline-block",
              verticalAlign: "middle"
            }} src="img/img8.png" alt='' />
            <span style={{
              display: "inline-block",
              verticalAlign: "middle"
            }}>&nbsp; 蜀ICP备2023003056号</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;