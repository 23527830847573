import React from "react";
import './less/middleTitle.scss'

const MiddleTitle = (props) => {
    return (
        <div className="middleTitle">
            <div className="title">{props.title}</div>
            <div style={{display:"flex"}}><div className="title_icon_yellow"></div><div className="title_icon_blue"></div></div>
        </div>
    )
}
export default MiddleTitle