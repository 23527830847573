/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect } from 'react';
import "./less/home.scss"
import { scrollTop } from "../../utils/scrollTop"
import events from '../../utils/event';
import HonorSlider from '../../components/HonorSlider';
import { HOME_LOG } from '../../utils/constants';



const Home = () => {

  useEffect(() => {
    scrollTop();
    document.title = "微客科技";
    events.emit(HOME_LOG, "/");
  }, [])

  return (

    <div className='home_img1'>
      <div id="chat_bottom_div"></div>
      {/* <div className='img_url'></div> */}
      <HonorSlider></HonorSlider>
      <div className='home_youshi'>
        <p style={{ textAlign: "center", fontSize: "36px", color: "#1A1A1A", lineHeight: "50px" }}> <img src="img/img1.png" alt="" /><span style={{ margin: "0 20px 0 20px" }}>我们的优势</span> <img src="img/img1.png" alt="" /></p>
        <div className='youshi'>
          <div className='mk'>
            <img src="img/edImg.png" alt="" />
            <span className='text'>额度高</span>
            <span className='text1'>额度最高20w</span>
          </div>
          <div className='mk'>
            <img src="img/xhImg.png" alt="" />
            <span className='text'>循环用</span>
            <span className='text1'>一次授信循环使用</span>
          </div>
          <div className='mk'>
            <img src="img/fkImg.png" alt="" />
            <span className='text'>放款快</span>
            <span className='text1'>最快5分钟到账</span>
          </div>
          <div className='mk'>
            <img src="img/lhImg.png" alt="" />
            <span className='text'>更灵活</span>
            <span className='text1'>最长可分期36个月</span>
          </div>
        </div>
      </div>

      <div style={{ width: "100%", backgroundColor: "#FAFAFB", display: "flex", flexDirection: "column" }}>


        <div className='home_jianjie'>
          <p style={{ textAlign: "center", fontSize: "36px", color: "#1A1A1A", lineHeight: "50px" }}> <img src="img/img1.png" alt="" /><span style={{ margin: "0 20px 0 20px" }}>公司简介</span> <img src="img/img1.png" alt="" /></p>
          <p style={{ textAlign: "center", fontSize: "18px", color: "#484848", lineHeight: "25px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 微客金融--成立于2009年探索普惠金融的创新发展道路，致力于打造满足用户需求的金融服务。以互联网为载体，利用大数据风控技术，不断开发农贷、消费分期等新产品并先后取得15个软件著作权以及网络安全等级保护(3级)备案证明，同时接入人行征信、百行征信，完善互联网风控体系，成为行业内领军人物。</p>
          <div style={{ width: "160px", height: "60px", borderRadius: "30px", background: "#F9F9F9", alignSelf: "center", margin: "72px 0 68px 0" }}>
            <img src="img/bottom.png" alt="" />
          </div>
        </div>
      </div>
    </div>

  )

}
export default Home