import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import './less/header.scss'
import events from "../utils/event";
import { HOME_LOG } from "../utils/constants";

const Header = () => {
   
    // const [logImg,setLogImg] = useState("img/log1.png");
      useEffect(()=>{
        events.on(HOME_LOG,imgLog)
        
        
      },[])
      const imgLog=(bool)=>{
        setActiveLink(bool);
      }

      const [activeLink, setActiveLink] = useState('');
   
  const handleLinkActive = (path) => {
    setActiveLink(path);
  }
    return (
        <div className="navHeader" id="navhead">
            <div style={{marginLeft:"200px"}}><img src="img/log.png" alt=''/></div>
            <div className="nav"> 
                <Link to="/"  onClick={() => handleLinkActive('/')} style={{ textDecoration: 'none',color: "#1A1A1A",fontSize:"18px" ,fontWeight:activeLink==="/"?"700":"400" ,borderBottom:activeLink==="/"?"2px solid #F5D25D":""}}> <p style={{height:"40px",lineHeight:"40px"}}>首页</p> </Link>
                <Link to="/Business"   onClick={() => handleLinkActive('/Business')} style={{ textDecoration: 'none' ,color: "#1A1A1A",fontSize:"18px",fontWeight:activeLink==="/Business"?"700":"400",borderBottom:activeLink==="/Business"?"2px solid #F5D25D":""}}> <p style={{height:"40px",lineHeight:"40px"}}>微客业务</p></Link>
                <Link to="/About"  onClick={() => handleLinkActive('/About')} style={{ textDecoration: 'none',color: "#1A1A1A",fontSize:"18px" ,fontWeight:activeLink==="/About"?"700":"400",borderBottom:activeLink==="/About"?"2px solid #F5D25D":""}}> <p style={{height:"40px",lineHeight:"40px"}}>关于微客</p></Link>
            </div>
        </div>
    )
}
export default Header