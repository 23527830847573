import React from 'react';
import "./less/middleColumn.scss";
import MiddleTitle from "./MiddleTitle"

const MiddleColumn = (props) => {
    const index = props.indexColor % 2 === 0
    return (
        <div className='middleColumn' style={{ backgroundColor: !index ? "rgba(240, 240, 240, 0.8)" : ""}}>
            {
                index ? (<>
                    <div className='className_text' style={{ alignItems: "center"}}>
                        {/* <p>{props.textHeader}</p> */}
                        <MiddleTitle title={props.textHeader}></MiddleTitle>
                        <span>{props.textContent}</span>
                    </div>
                    <div className='middleColumn_img'><img src={props.url} alt=''/></div>
                </>) : (<>
                    <div className='middleColumn_img'><img src={props.url} alt=''/></div>
                    <div className='className_text' style={{ alignItems: "center"}}>
                        {/* <p>{props.textHeader}</p> */}
                        <MiddleTitle title={props.textHeader}></MiddleTitle>
                        <span>{props.textContent}</span>
                    </div>

                </>)
            }

        </div>
    )
}
export default MiddleColumn;