/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import "./less/about.scss"
import {scrollTop} from "../../utils/scrollTop"
import events from '../../utils/event';
import { HOME_LOG } from '../../utils/constants';

export default class Home extends React.Component {
  componentDidMount(){
    scrollTop();
    document.title ="关于微客-微客科技"
    events.emit(HOME_LOG,"/About")

  }
  render() {
    return (
      <div className='about'>
      <div id="chat_bottom_div"></div>  
        <div className='backImg'></div>
       <div className='guanyuweike'>
        <p style={{textAlign:"center",fontSize:"36px", color:"#1A1A1A",lineHeight:"50px"}}> <img src="img/img1.png" alt="" /><span style={{margin:"0 20px 0 20px"}}>关于微客金融</span> <img src="img/img1.png" alt="" /></p>
        <p style={{fontSize:"20px", color:"#484848",lineHeight:"28px",padding:"0 60px 0 60px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;微客金融--成立于2009年探索普惠金融的创新发展道路，致力于打造满足用户需求的金融服务。以互联网为载体，利用大数据风控技术，不断开发农贷、消费分期等新产品并先后取得15个软件著作权以及网络安全等级保护(3级)备案证明，同时接入人行征信、百行征信，完善互联网风控体系，成为行业内领军人物。</p>
       </div>
       <div className='rongyuzizhi'>
        <p style={{textAlign:"center",fontSize:"36px", color:"#1A1A1A",lineHeight:"50px"}}> <img src="img/img1.png" alt="" /><span style={{margin:"0 20px 0 20px"}}>资质荣誉</span> <img src="img/img1.png" alt="" /></p>
       <div className='rongyuzizhiImg'></div>
       </div>
       <div className='guanyuwomen'>
       <p style={{textAlign:"center",fontSize:"36px", color:"#1A1A1A",lineHeight:"50px"}}> <img src="img/img1.png" alt="" /><span style={{margin:"0 20px 0 20px"}}>关于我们 </span> <img src="img/img1.png" alt="" /></p>
        <p style={{fontSize:"20px", color:"#484848",lineHeight:"28px",padding:"0 60px 0 60px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	   微客金融一直致力于探索普惠金融的创新发展道路。我们以满足用户需求为己任，不断打造各类金融服务。经过多年的发展，我们除了总部成都以外，已在西安、北京、苏州设立了分公司。作为一家以服务为核心的企业，我们始终秉持着诚信、创新的价值观，努力为客户提供更加优质、便捷的金融服务，助力他们实现财富增长和梦想实现。</p>
        
       </div>
       <div className='gywmImg'>
     
       </div>

  
      </div>
    )
  }
}
