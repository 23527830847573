import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'; // 主样式
import 'slick-carousel/slick/slick-theme.css'; // 主题样式（可选，用于定制化）
import "./less/honorSlider.scss"

const HonorSlider = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true, //开启自动滚动
        autoplaySpeed: 2000, //每两秒滚动一次
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      const images = ['img/banner1.png', 'img/banner2.png'].map((src,index) => (
        <div key={index} className="imgBox">
          <div  style={{width:"100%",height:"900px",backgroundImage:`url(${src})`}}></div>
        </div>
      ));
    return (
       <div style={{width:"100%"}}>
         <Slider {...settings}>
        {images}
      </Slider>
       </div>
    )
}
export default HonorSlider
