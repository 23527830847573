/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import "./less/business.scss"
import MiddleColumn from '../../components/MiddleColumn';
import {scrollTop} from "../../utils/scrollTop"
import events from '../../utils/event';
import { HOME_LOG } from '../../utils/constants';
export default class Home extends React.Component {
  componentDidMount(){
    scrollTop();
    document.title ="微客业务-微客科技"
    events.emit(HOME_LOG,"/Business")
  }
  
  render() {
    const businessArr = [
      {
        textHeader:"大额信用贷款",
        textContent:"信用贷款依托旗下小额贷款公司，践行安全可靠的普惠金融信贷服务，为企业主提供生意经营资金周转服务",
        url:"img/yewuimg1.png"
      },
      {
        textHeader:"小额分期",
        textContent:"小额短期借款服务为客户提供一站式消费信贷解决方案。基于坚实的线上运营和风控能力，定制化服务，根据客群匹配相应产品流程。智能筛选机制，从流量红海中高效甄别优质信贷资源",
        url:"img/yewuimg2.png"
      },
      {
        textHeader:"消费分期",
        textContent:'微客消费分期是一款与实体消费场景相结合，为城市青年消费者提供订单分期的金融服务。元丰还在积极探案各种不同类型的消费场景及合作伙伴，构造"消费场景+金融服务"的消费金融体系。',
        url:"img/yewuimg3.png"
      },
      {
        textHeader:"自研SaaS平台”微客平台”",
        textContent:"一站式解决方案由精准营销、CRM(客户关系管理)、BPM(业务流程管理)、BI(商业智能化)多个功能模块组成，旨在为我们的客户解决其业务中的痛点，帮助其拓展和激活客户群，实现业务流程数字化，提高整体运营效率。",
        url:"img/yewuimg4.png"
      }
      
    ]
    return (
      <div id="scrollableDiv" className='business'>
      <div id="chat_bottom_div"></div>  
        
          <div className='backImg'> 
          <p className='p1'>自研SaaS平台“微客平台"为我们的客户提供一站式解決方案</p>
          <p className='p2'>激活客户群，实现业务流程数字化，提高整体运营效率</p>
          </div>
          {
            businessArr.map((item,index)=>(
              <MiddleColumn key={index} textHeader={item.textHeader} textContent={item.textContent} url={item.url} indexColor={index}></MiddleColumn>

            ))
          }

      </div>
    )
  }
}
