import React, { useEffect} from 'react';
import Home from "./pages/Home/index"
import About from "./pages/About/index"
import Business from "./pages/Business/index"
import Header from "./components/Header"
import Footer from './components/footer';
import { Route, Routes } from 'react-router-dom';
import autofit from "autofit.js"


function App() {
  
  useEffect(() => {
    const options = {
      designHeight: 1080,
      designWidth: 1920,
      renderDom: "#appId", // 或者是你需要自适应布局的容器ID
      resize: true,
      // 其他可选配置项...
    };
    

    autofit.init(options);
   
    // 如果需要在组件卸载时清理资源，可以在return的函数中处理
    return () => {
      // 若autofit.js提供了销毁方法，则在此处调用
      // 若没有提供，此处可能不需要写任何内容
    };
  }, []);
  useEffect(()=>{
    let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    console.log(top);
  })




  

  return (
    <div className='App' id='appId'>
      <Header></Header>
      <Routes>
   
      <Route path='/' element={ <Home />} /> 
      <Route path='/About' element={ <About />} /> 
      <Route path='/Business' element={ <Business />} /> 
    </Routes>
    <Footer></Footer>
    </div>
  );
}

export default App;
